import React, { useState, useEffect, lazy, Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import TrainTable from "./components/TrainTable";
import TripPlanner from "./components/TripPlanner";
import Alert from "./components/Alert";
import Footer from "./components/Footer";
import "./App.css";
import { Helmet } from "react-helmet-async";
import stationsData from ".//data/stationsData.json";
import RailroadTracks from "./components/RailroadTracks";

import AIAlerts from "./components/AIAlerts";
import ServiceAlert from "./components/ServiceAlert";
import Navbar from "./components/Navbar";
import schedTypes from "./SchedTypes.js";
const TrainCard = lazy(() => import("./components/TrainCard")); // Your station data

const App = () => {
  const [trains, setTrains] = useState([]);
  const [updated, setUpdated] = useState("");

  useEffect(() => {
    const fetchData = () => {
      fetch("https://caltrain.live/data.json")
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not OK");
          return response.json(); // Directly parse JSON here
        })
        .then((data) => {
          setTrains(data.trains); // Update trains state
          setUpdated(data.updated); // Update timestamp
        })
        .catch((error) => console.error("Fetching error:", error));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content="app-id=6737343619, app-argument=caltrainlive"
        />
      </Helmet>
      <Router>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={<Home trains={trains} updated={updated} />}
          />
          <Route
            path="/:trainId"
            element={
              <Suspense fallback={<div>Loading train details...</div>}>
                <TrainCard trains={trains} />
              </Suspense>
            }
          />
          <Route
            path="/planner/:originId?/:destinationId?"
            element={<TripPlanner />}
          />
        </Routes>
      </Router>
    </div>
  );
};

const Home = ({ trains, updated }) => {
  const [showTracks, setShowTracks] = useState(false); // Toggle for mobile
  const northboundStops = stationsData.filter(
    (station) =>
      station.scheduleTypes.includes(schedTypes.local) ||
      station.scheduleTypes.includes(schedTypes.limited) ||
      station.scheduleTypes.includes(schedTypes.express) ||
      station.scheduleTypes.includes(schedTypes.southcounty)
  );
  const southboundStops = [...northboundStops].reverse(); // Reverse for southbound
  const isMobile = window.innerWidth <= 768;
  const isAppleDevice = /Macintosh|MacIntel|iPhone|iPad|iPod/.test(
    navigator.userAgent
  );

  return (
    <div className="home-container">
      {/* Always show the toggle button on mobile */}
      {isMobile && (
        <button
          className="show-tracks-button"
          onClick={() => setShowTracks(!showTracks)}
        >
          {showTracks ? "Hide Tracks" : "Show Tracks"}
        </button>
      )}

      {/* Show tracks on mobile if toggled, or always on desktop */}
      {(!isMobile || showTracks) && (
        <div className="track-container-wrapper">
          <RailroadTracks
            stops={southboundStops}
            trains={trains}
            direction="Southbound"
          />
        </div>
      )}

      {/* Show tables on mobile if tracks are not toggled, or always on desktop */}
      {(!isMobile || !showTracks) && (
        <div className="table-container">
          <div>
            <AIAlerts />
          </div>

          <div>
            <ServiceAlert />
          </div>

          <TrainTable
            direction="Northbound"
            trains={trains.filter((t) => t.direction === "N")}
          />
          <TrainTable
            direction="Southbound"
            trains={trains.filter((t) => t.direction === "S")}
          />
          {isAppleDevice && <Alert />}
          <Footer updated={updated} />
        </div>
      )}
    </div>
  );
};

export default App;
