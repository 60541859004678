/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./AIAlerts.css";

const AIAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [ailastFetchTime, aisetLastFetchTime] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const CACHE_INTERVAL = 3 * 60 * 1000; // 3 minutes in milliseconds

  const iconMapping = {
    TRACK_NOTIFICATION: { icon: "train", color: "green" },
    DELAY: { icon: "hourglass_empty", color: "yellow" },
    CANCELATION: { icon: "cancel", color: "red" },
    PLATFORM_CHANGE: { icon: "alt_route", color: "green" },
    TRESPASSER: { icon: "directions_run", color: "red" },
    POLICE_ACTIVITY: { icon: "siren", color: "red" },
    OTHER: { icon: "train", color: "green" },
  };

  const fetchAIAlerts = () => {
    if (ailastFetchTime && Date.now() - ailastFetchTime < CACHE_INTERVAL) {
      console.log("Using cached AI alerts; last fetch was within 3 minutes.");
      return;
    }

    aisetLastFetchTime(Date.now());

    fetch("https://caltrain.live/caltrain-alerts.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched AI alerts:", data);
        const parsedAlerts = data.alerts.map((alert) => ({
          id: Math.random().toString(36).substr(2, 9),
          alertType: alert.alert_type,
          affectedTrain: alert.affected_train,
          alertText: alert.alert_text,
        }));
        setAlerts(parsedAlerts);
      })
      .catch((error) => console.error("Error fetching AI alerts:", error));
  };

  useEffect(() => {
    fetchAIAlerts();
    const interval = setInterval(fetchAIAlerts, CACHE_INTERVAL);
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [CACHE_INTERVAL, fetchAIAlerts]);

  return alerts.length > 0 ? (
    <div className="ai-alerts">
      <div
        className="ai-alerts-header"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3>AI-Summarized Caltrain Alerts {isCollapsed && `(${alerts.length})`}</h3>
        <span className="collapse-icon">{isCollapsed ? "+" : "−"}</span>
      </div>
      {!isCollapsed && (
        <ul className="ai-alerts-list">
          {alerts.map((alert) => {
            const { icon, color } = iconMapping[alert.alertType] || {};
            return (
              <li key={alert.id} className="ai-alert">
                <span
                  className="material-icons"
                  style={{ color, marginRight: "8px" }}
                >
                  {icon}
                </span>
                {`${alert.alertText}`}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  ) : null; // Render nothing if there are no alerts
};

export default AIAlerts;
