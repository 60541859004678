/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./ServiceAlert.css";

const ServiceAlert = () => {
  const [alerts, setInternalAlerts] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const CACHE_INTERVAL = 3 * 60 * 1000; // 3 minutes in milliseconds

  const fetchServiceAlerts = () => {
    if (lastFetchTime && Date.now() - lastFetchTime < CACHE_INTERVAL) {
      console.log("Using cached alerts; last fetch was within 3 minutes.");
      return;
    }

    setInternalAlerts([]); // Clear current alerts
    setLastFetchTime(Date.now());

    fetchInternalServiceAlerts();
  };

  const fetchInternalServiceAlerts = () => {
    fetch("https://caltrain.live/alerts.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Got internal alerts:", data);
        const currentDate = new Date();
        const uniqueAlerts = new Set();
        const internalAlerts = data
          .map((alert) => ({
            id: Math.random().toString(36).substr(2, 9),
            message: alert.alert,
            start: alert.start ? new Date(alert.start) : null,
            end: alert.end
              ? new Date(alert.end)
              : new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // Default 1 year
            link: alert.link ? new URL(alert.link) : null,
            isTrackAlert: false,
            isInternalAlert: true,
          }))
          .filter(
            (alert) =>
              (!alert.start || currentDate >= alert.start) &&
              (!alert.end || currentDate <= alert.end)
          )
          .filter((alert) => {
            // Create a unique key based on relevant fields
            const uniqueKey = `${alert.message}`;
            if (uniqueAlerts.has(uniqueKey)) {
              return false; // Skip duplicate alerts
            }
            uniqueAlerts.add(uniqueKey);
            return true;
          });

        setInternalAlerts(internalAlerts);
        //fetchExternalServiceAlerts(); // Fetch external alerts after internal
      })
      .catch((error) =>
        console.error("Error fetching internal alerts:", error)
      );
  };

  // const fetchExternalServiceAlerts = () => {
  //   fetch("https://www.caltrain.com/gtfs/api/v1/servicealerts/3655")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const externalAlerts = data
  //         .filter((alert) => alert.Source === "GTFS RT")
  //         .map((alert) => {
  //           const text =
  //             alert.Alert.HeaderText?.Translation?.find(
  //               (t) => t.Language === "en"
  //             )?.Text || "";
  //           const linkMatch = text.match(/(https?:\/\/caltrain\.com\S*)/i);
  //           return {
  //             id: Math.random().toString(36).substr(2, 9),
  //             message: text,
  //             link: linkMatch ? new URL(linkMatch[0]) : null,
  //             isTrackAlert: text.toLowerCase().includes("track"),
  //             isInternalAlert: false,
  //           };
  //         })
  //         .filter(
  //           (alert, index, self) =>
  //             self.findIndex((a) => a.message === alert.message) === index
  //         ); // Filter to remove duplicates by message

  //       setInternalAlerts((prevAlerts) => [...prevAlerts, ...externalAlerts]);
  //     })
  //     .catch((error) =>
  //       console.error("Error fetching external alerts:", error)
  //     );
  // };

  useEffect(() => {
    fetchServiceAlerts();
    const interval = setInterval(fetchServiceAlerts, CACHE_INTERVAL);
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [CACHE_INTERVAL, fetchServiceAlerts]);

  return alerts.length > 0 ? (
    <div className="service-alerts">
      <div
        className="service-alerts-header"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3>Service alerts provided by Caltrain Companion {isCollapsed && `(${alerts.length})`}</h3>
        <span className="collapse-icon">{isCollapsed ? "+" : "−"}</span>
      </div>
      {!isCollapsed && (
        <ul>
          {alerts.map((alert) => (
            <li
              key={alert.id}
              className={alert.isTrackAlert ? "track-alert" : ""}
            >
              <p>{alert.message}</p>
              {alert.link && (
                <a href={alert.link} target="_blank" rel="noopener noreferrer">
                  Learn More
                </a>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null; // Render nothing if there are no alerts
};

export default ServiceAlert;
